
import Plyr from 'plyr'
import media from '~/mixins/media'

export default {
  name: 'VideoLocal',

  mixins: [media],

  props: {
    video: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      player: null,
    }
  },

  computed: {
    webmUrl() {
      if (this.video.webm) {
        if (this.video.webm.data) {
          if (this.video.webm.data.attributes) {
            return this.setAssetDomain(this.video.webm.data.attributes.url)
          }
        }
      }
      return null
    },
    mp4Url() {
      if (this.video.mp4) {
        if (this.video.mp4.data) {
          if (this.video.mp4.data.attributes) {
            return this.setAssetDomain(this.video.mp4.data.attributes.url)
          }
        }
      }
      return null
    },
  },
  mounted() {
    this.player = new Plyr(this.$refs.playerRef, { ratio: '1:1', autoplay: true, muted: true, loop: { active: true }, controls: [], playsinline: true })
    this.player.on('ready', () => {
      this.$emit('ready')
    })
  },
}
